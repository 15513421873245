.map-block-wrapper{
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0px 180px 0px;
    .map-block-content{
        width: 100%;
        .map-block-capture{
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            padding-bottom: 20px;
        }
       
        .map-block-description{
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            padding-bottom: 40px;
        }
        @media (max-width:428px) {
            .map-block-capture{
                font-size: 30px;
                line-height: 27px;
            }
            .map-block-description{
                font-size: 24px;
                line-height: 27px;
            }
            
        }
        .map-block-content-wrapper{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .map-img{
                width: 360px;
                height: 580px;
            }
            .map-img video{
                height: 100%;
            }
            @media (max-width:800px) {
                .map-img{
                    width: 100%;
                    height: auto;
                }
                .map-img video{
                    height: 100%;
                    width: 90%;
                    display: block;
                    margin: 0 auto;
                
                }
            }
        }
        .map-block-map-wrapper{
            width: 780px;
            height: 580px;
        }
    }
}
@media (max-width:1025px) {
    .map-block-wrapper{
        width: 100%;
        .map-block-content{
            width: 96%;
            padding: 20px;
        }
    }
}
@media (max-width:801px) {
    .map-block-wrapper{
        width: 100%;
        .map-block-content{
            width: 100%;
            padding: 0px;
            .map-block-content-wrapper{
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .map-block-map-wrapper{
                width: 90%;
                height: 480px;
                padding-top: 50px;
            }
        }
    }
}