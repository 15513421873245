.dropdown{
    width: 80px;
    position: relative;
    .languageSelectorImage{

        padding: 0px 6px 0px 0px;
    }
    .languageSelectorImage-main{
        opacity: 1;
    }
    .dropdown-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 55px;
        height: 24px;
        cursor: pointer;
    }
    .dropdown-content{
        position: absolute;
        padding: 6px;
        top: 50px;
        left: 4px;
        background: #ffff;
        border-radius: 8px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
        .dropdown-item{
            display: flex;
            align-items: center;
            padding: 6px;
            cursor: pointer;
            opacity: 0.5;
            text-decoration: none;
            color: black;
        }
        .dropdown-item:hover{
            opacity: 1;
        }
    }
    
}