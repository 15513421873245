.header-main-wrapper-mob{
    max-width: 100%;
    position: sticky;
    top:0px;
    background-color: #ffff;
    z-index: 1001;
    display: none;
    .header-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        
    }
    .header-logo img {
        width: 60%;
    }
    .drop-block{
        height: 100vh;
        .nav-wrapper{
            width: 152px;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            color: #0E0E0E;
            .nav-item{
                width: 100%;
                padding: 0px 0px 20px 20px;
                
            }
            .nav-item a {
                text-decoration: none;
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #0E0E0E;
            }
            
        }
        .language-block-and-button-wrapper{
            width: 320px;
            .language-wrapper{
                display: flex;
                padding: 30px 0px 30px 20px;
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #BBBBBB;
                .dropdown-item{
                    padding: 0px 20px 0px 0px;
                }
                
            }
            .red{
                color: red;
            }
            .button-wrapper-mob{
                height: 56px;
                left: 21px;
                top: 410px;
                background: #FFFFFF;
                border: 1px solid #0E0E0E;
                border-radius: 10px;
                margin: 0px 0px 0px 20px;
                font-weight: 500;
                font-size: 18px;
                line-height: 140%;
                color: #0E0E0E;
            }
            .button-wrapper-mob button{
                font-weight: 500;
                font-size: 24px;
                line-height: 140%;
                color: #0E0E0E;
            }
        }
    }
}
@media (max-width:1025px) {
    .header-main-wrapper-mob{
        display: block;
    }
}