.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    overflow: hidden;
    z-index: 4;
}
.modal-content{
    position: relative;
    transform:scale(0.5);
    -webkit-transform:scale(0.5);
    -moz-transform:scale(0.5);
    -ms-transform:scale(0.5);
    -o-transform:scale(0.5);
    transition: 0.4s all;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    -ms-transition: 0.4s all;
    -o-transition: 0.4s all; 
}
@media (max-width:1025px) {
  .modal-content{
    margin-left: 20px;
    margin-right: 20px;
    
  }
}
@media (max-width:801px) {
  .modal-content{
    margin-left: 20px;
    margin-right: 20px;
    overflow-y: scroll;

    border-radius: 16px;
  }
  
}
@media (max-width:601px) {
  .modal-content{
    overflow-y: scroll;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}
}
@media (max-width:428px) {
  .modal-content{
    overflow-y: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    width: 100vw;
    height: 100vh;
    margin: 0px;
    padding: 0px;
}

}
.modal.active{
  opacity: 1;
  pointer-events: all;
  z-index: 10002;
  display: flex;
  justify-content: center;
  transition: 100ms all;
  -webkit-transition: 100ms all;
  -moz-transition: 100ms all;
  -ms-transition: 100ms all;
  -o-transition: 100ms all;
} 
@media (max-width:1025px) {
  .modal.active{
    overflow-y: scroll;
  }
}
.modal-content.active{
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  .close{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 32px;
    top: 33px;
    cursor: pointer;
    z-index: 1;
  }
  @media (max-width:1025px) {
    .close{
        right: 29px;
        top: 23px;
    }
  }
  @media (max-width:801px) {
    .close{
        right: 26px;
        top: 15px;
    }
  }
  @media (max-width:601px) {
    .close{
        right: 29px;
    }
  }
  @media (max-width:533px) {
    .close{
        right: 21px;
    }
  }
  
}



