body {
    font-family: 'Inter', sans-serif;
}
img{
    display: block;
}
.app{
    width: 100%;
    .global-content-wrapper{
        max-width: 1440px;
        margin: 0 auto;
        background-color: #ffff;
    }
    
}