.privacy-main-wrapper{
    width: 100%;
    .privacy-header{
        width: 100%;
        background-image: url(../../../assets/img/privacyImg.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        text-align: center;
        .privacy-capture{
            max-width: 507px;
            margin: 0 auto;
            font-weight: 600;
            font-size: 50px;
            line-height: 120%;
            color: #FFFFFF;
            padding:166px 0px 244px 0px ;
        }
    }
    .privacy-text{
        max-width: 1000px;
        margin: 0 auto;
        padding: 100px 0px 180px 0px;
        .privacy-description{
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            padding: 0px 20px 0px 20px;
        }
    }
    .privacy-text h2{
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        padding-top: 30px;
        padding: 0px 10px 0px 10px;
    }
    .privacy-text ul li{
        list-style: none;
        padding: 0px 0px 10px 0px;
        padding: 0px 10px 0px 0px;
    }
    .privacy-text ol li{
        padding: 0px 0px 10px 0px;
        padding: 0px 10px 0px 0px;
    }
    @media (max-width:800px) {
        .privacy-text h2 {
            font-size: 25px;
            line-height: 30px;
        }
    }
}
