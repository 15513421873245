.rev-wrapper{
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 180px;
    display: none;
    .rev-content{
        opacity: 0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -ms-transform: scale(0.5);
        -o-transform: scale(0.5);
        .rev-capture{
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            padding-bottom: 40px;
        }
        @media (max-width:428px) {
            .rev-capture{
                font-size: 30px;
                line-height: 27px;
            }
            
        }
        .rev-slide-block{
            width: 100%;
            position: relative;
            .item{
                width: 100%;
                .rev-slide-item{
                    position: relative;
                    width: 360px;
                    height: 314px;
                    background: #FFFFFF;
                    border: 1px solid #BBBBBB;
                    border-radius: 10px;
                    text-align: center;
                    margin: 0 auto;
                    .rev-text{
                        width: 300px;
                        padding: 30px 30px 12px 30px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                    }
                    .absolute-content{
                        position: absolute;
                        top: 192px;
                        left: 134px;
                        width: 80px;
                        height: 110px;
                        .rev-img{
                            padding-bottom: 8px;
                        }
                        .rev-bottom-capture{
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 140%;
                        }
                    }
                }
            }
            .slider-button-prev{
                position: absolute;
                left: 380px;
                top: 336px;
            }
            .slider-button-prev button{
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
                color: #909090;
                display: flex;
                align-items: center;
            }
            .slider-button-prev img{
                display: block;
                padding-right: 6px;
            }
            .slider-button-next button{
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
                color: #D30D1E;
                display: flex;
                align-items: center;
            }
            .slider-button-next img{
                display: block;
                padding-left: 6px;
            }
            .slider-button-next{
                position: absolute;
                right: 380px;
                top: 336px;
            }
            @media (max-width:1025px) {
                .slider-button-next{
                    display: none;
                }
                .slider-button-prev{
                    display: none;
                }
            }
        }
    }
    .rev-content-active{
        opacity: 1;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}
}