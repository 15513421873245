.not-found-main-wrapper{
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 0px 100px 0px;
    .not-found-content{
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .not-found-image{
            max-width: 570px;
        }
        .not-found-image img{
            max-width: 100%;
            display: block;
        }
        .explanation-block{
            max-width: 374px;
            margin: 0px 0px 0px 60px;
            .explanation-capture{
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                padding: 0px 0px 16px 0px;
            }
            .explanation-description{
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                color: #A9A9A9;
                padding: 0px 0px 24px 0px;
            }
            .explanation-link{
                width: 255px;
                height: 56px;
                background: #D30D1E;
                border-radius: 10px;
                color: #ffff;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        @media (max-width:428px) {
            .explanation-block{
                width: 90%;
                margin: 0 auto;
                text-align: center;
                padding-top: 30px;
                .explanation-capture{
                    font-size: 28px;
                }
                .explanation-link{
                    width: 100%;
                }
            }
        }
        a{
            text-decoration: none;
        }
    }
}
