.main-success-wrapper{
    max-width: 600px;
    background: #ffff;
    border-radius: 16px;
    .success-content{
        padding: 130px 133px 190px 133px;
        text-align: center;
        .success-img{
            padding-bottom: 20px;
        }
        .success-capture{
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
        }
        .success-description{
            padding-top: 11px;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            color: #A9A9A9;
        }
    }
}
@media (max-width: 428px){
    .main-success-wrapper {
        width: 100vw;
        height: 100vh;
        padding-top: 50px;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}