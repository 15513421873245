.question-main{
    width: 100%;
    padding-bottom: 24px;
    .question-wrapper{
        width: 100%;
        background: #FFFFFF;
        border-radius: 16px;
        color: #1D1C38;
    
        .question-content{
            max-width: 1200px;
            margin: 0 auto;
            cursor: pointer;
            .question-item{
                opacity: 0;
                transition: all 1s;
                -webkit-transition: all 1s;
                -moz-transition: all 1s;
                -ms-transition: all 1s;
                -o-transition: all 1s;
                transform: translateY(50px);
                -webkit-transform: translateY(50px);
                -moz-transform: translateY(50px);
                -ms-transform: translateY(50px);
                -o-transform: translateY(50px);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 16px 0px 16px;
                height: 90px;
                background: #F1F0F0;
                border-radius: 10px;
                .question{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    padding: 24px 0px 24px 0px;
                }
                .question-item-active{
                    color: #D30D1E;
                }
                .question-img{
                    cursor: pointer;
                    padding-left: 20px;
                }
                @media (max-width:801px) {
                    .question{
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
            .question-item-animation-active{
                opacity: 1;
                transition: all 1s;
                -webkit-transition: all 1s;
                -moz-transition: all 1s;
                -ms-transition: all 1s;
                -o-transition: all 1s;
                transform: translateY(0px);
                -webkit-transform: translateY(0px);
                -moz-transform: translateY(0px);
                -ms-transform: translateY(0px);
                -o-transform: translateY(0px);
}
            .answer{
                display: none;
                
            }
            .answer-active{
                display: block;
                font-size: 20px;
                line-height: 30px;
                margin-top: -8px;
                background: #F1F0F0;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                border-radius: 0px 0px 10px 10px;
                padding: 10px 20px 24px 20px;
                -webkit-border-radius: 0px 0px 10px 10px;
                -moz-border-radius: 0px 0px 10px 10px;
                -ms-border-radius: 0px 0px 10px 10px;
                -o-border-radius: 0px 0px 10px 10px;
}
            @media (max-width:801px) {
                .answer-active{
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        
    }
}