.offer-wrapper{
    width: 100%;
    background: #F1F0F0;
    .offer-content{
        max-width: 1200px;
        margin: 0 auto;
        padding: 80px 0px 80px 0px;

        .offer-header{
            max-width: 849px;
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            padding-bottom: 40px;
            margin: 0 auto;
        }
        @media (max-width:428px) {
            .offer-header{
                width: 95%;
                margin:  0 auto;
                font-size: 26px;
                line-height: 37px;
            }
            
        }
        .offer-main{
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .timer-and-form-wrapper{
                opacity: 0;
                transform: scale(0);
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -ms-transform: scale(0);
                -o-transform: scale(0);
                width: 570px;
                margin-left: -571px;
                transition: all 700ms;
                -webkit-transition: all 700ms;
                -moz-transition: all 700ms;
                -ms-transition: all 700ms;
                -o-transition: all 700ms;
            }
            .timer-and-form-wrapper-active{
                opacity: 1;
                margin-left: 0px;
                transition: all 0.5s;
                -webkit-transition: all 700ms;
                -moz-transition: all 0.5s;
                -ms-transition: all 0.5s;
                -o-transition: all 0.5s;
                transform: scale(1);
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
            }
            .offer-image{
              max-width: 570px;
              margin-right: -589px;
              opacity: 0;
              transform: scale(0);
              -webkit-transform: scale(0);
              -moz-transform: scale(0);
              -ms-transform: scale(0);
              -o-transform: scale(0);
              transition:all 700ms ;
              -webkit-transition:all 700ms ;
              -moz-transition:all 700ms ;
              -ms-transition:all 700ms ;
              -o-transition:all 700ms ;
            }
            .offer-image-active{
                max-width: 570px;
                margin-right: 0px;
                opacity: 1;
                transform: scale(1);
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transition:all 700ms ;
                -webkit-transition:all 700ms ;
                -moz-transition:all 700ms ;
                -ms-transition:all 700ms ;
                -o-transition:all 700ms ;
            }
            .offer-image img{

                width: 100%;
                display: block;
                padding-top: 17px;
            }
        }
        @media (max-width:1025px) {
            .offer-main{
                justify-content: center;
            }
            .offer-image{
                padding-top: 40px;
            }
        }
        @media (max-width:800px) {
            .offer-main{
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .timer-and-form-wrapper-active{
                    width: 100%;
                }
                .offer-image{
                    width: 100%;
                }
            }
        }
    }
}