.hero-wrapper{
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    width: 100%;
    background-image: url(../../assets/img/hero_bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .hero-conent-wrapper{
        max-width: 601px;
        margin: 0 auto;
        color: #ffff;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 140px 0px 235px 0px;
        .hero-capture{
            font-weight: 600;
            font-size: 50px;
            line-height: 120%;
            padding: 13px 0px 20px 0px;
        }
        .hero-description{
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
        }
        @media (max-width:428px) {
            .hero-capture{
                width: 90%;
                margin:  0 auto;
                font-size: 40px;
                line-height: 120%;
            }
            .hero-description{
                font-size: 20px;
                line-height: 19px;
            }
        }
        .hero-button{
            width: 312px;
            height: 56px;
            background: #D30D1E;
            border-radius: 10px;
            margin: 20px 0px 13px 0px;
            
        }
        .hero-button button{
            color: #ffff;
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
        }
    }
}
.active{
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}
img{
    display: block;
}