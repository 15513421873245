.clients-wrapper{
    width: 100%;
    background-color: #ffff;
    padding-top: 110px;
    position: relative;
    .clients-wrapper-content{
        padding: 70px 0px 80px 0px;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .clients-text-block{
            max-width: 600px;
            text-align: center;
            .clients-header{
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                padding-bottom: 20px;
            }
            @media (max-width:428px) {
                .clients-header{
                    font-size: 26px;
                    line-height: 27px;
                }
                
            }
        }
        .clients-block-wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 40px;
            .clients-item-link{
                width: 360px;
                height: 200px;
                background: #FFFFFF;
                box-shadow: 4px 4px 20px rgba(14, 14, 14, 0.1);
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            @media (max-width:1025px) {
                .clients-item-link{
                    margin: 20px auto;
                }
            }
        }
    }
}