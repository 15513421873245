.b2b {
    max-width: 1200px;
    margin: 0 auto;

    .b2b-content {
        width: calc(100% - 40px);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .b2b-sescription {
            max-width:840px;
            padding: 0px 20px 40px 0px;
            text-align: justify;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #555555;
        }
        .signature-block{
           padding: 0px;
            .signature-links{
                width: 260px;

                .signature {
                    font-size: 20px;
                    color: #131313;
                    padding: 20px 0px 10px 0px;
                    font-weight: 500;
                }
    
                .link {
                    padding-bottom: 0px;
                }
    
                .link a {
                    text-decoration: none;
                    color: #2b2b2b;
                    font-weight: 500;
                }
    
                .link a:hover {
                    color: #D30D1E;
                }
            }
            .b2b-image{
            padding-bottom: 40px;
            }
            .b2b-image img{
                width: 180px;
                max-width: 180px;
                padding: 0px;
               
            }
        }
        
    }
    @media (max-width:1000px) {
        .b2b-content{
            flex-direction: column;
            .b2b-sescription{
                padding: 0px 0px 40px 0px;
            }
            .signature-block{
                margin: 0 auto;
            }
        }
    }

}

