.timer-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    padding-bottom: 20px;
    .time{
        width: 100px;
        height: 119px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
        .number{
            font-weight: 400;
            font-size: 60px;
            line-height: 100%;
        }
        .description{
            padding-top: 20px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
        }
    }
    @media (max-width:428px) {
        .time{
            padding: 0px;
            margin: 0 auto;
        }
    }
    .delimiter{
        font-weight: 400;
        font-size: 60px;
        line-height: 100%;
        padding-top: 6px;
        padding-right: 20px;
    }
}