.faq-wrapper{
    width: 100%;
   
    .faq-content-wrapper{
        max-width: 1200px;
        margin: 0 auto;
        padding: 180px 20px 60px 20px;
        .faq-capture{
            text-align: center;
            font-weight: 600;
            font-size: 36px;
            line-height: 44px;
            font-size: 48px;
            line-height: 48px;color: #1D1C38;
            padding: 0px 0px 40px 0px;
        }
        @media (max-width:428px) {
            .faq-capture{
                font-size: 30px;
                line-height: 27px;
            }
            
        }
    }
    @media (max-width:600px) {
        .faq-content-wrapper{
            padding: 40px 20px 0px 20px;
        }
    }
   
}
@media (max-width:801px) {
    .faq-wrapper{
     padding-top: 0px;
    } 
 }