.footer-wrapper{
    width: 100%;
    background: #F1F0F0;
    display: flex;
    padding: 0;
    margin: 0;
    .footer-left{
        max-width: 690px;

        background: #222222;
        .left-content{
            max-width: 500px;
            color: #ffff;
            padding: 50px 70px 150px 120px;
            .footer-capture{
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
                padding-bottom: 30px;
            }
        }
        @media (max-width:1025px) {
            .left-content{
                padding: 50px 50px 130px 27px;
            }
        }
    }
    .footer-right{
        .right-content{
            padding: 50px 0px 40px 40px;
            position: relative;
            .footer-logo{
                width: 300px;
            }
            .footer-logo img{
                width: 80%;
              
                left: 15px;
                top: 6px;
            }
            @media (max-width:533px){
                .footer-logo img {
                    width: 60%;
                }
            }
            .footer-nav-wrapper{
                width: 547px;
                padding: 30px 0px 0px 30px;
                display: flex;
                justify-content: space-between;
                .footer-menu{
                    .nav-item{
                        padding-bottom: 9px;
                    }
                    .nav-item :hover{
                        color: #D30D1E;
                    }
                    .nav-item a{
                        text-decoration: none;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 140%;
                        color: #0E0E0E;
                    }
                }
                .footer-nav{
                    width: 121px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 25px;
                    .footer-links{
                        width: 92px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        padding: 35px 0px 0px 0px;
                    }
                    .footer-links span{
                        padding: 0px 15px 0px 0px;
                    }
                }
                .footer-contacts{
                    width: 292px;
                    .footer-contacts-capture{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 140%;
                        padding: 0px 0px 12px 0px;
                    }
                    .footer-contacts-content{
                        .contacts-footer-item{
                            display: flex;
                            align-items: center;
                            padding-bottom: 10px;
                            .contacts-footer-item-image{
                                padding-right: 8px;
                                padding-top: 4px;
                            }
                            .phone{
                                padding-right: 16px;
                            }
                            .phone a{
                                text-decoration: none;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 140%;
                                color: #0E0E0E;
                            }
                        }
                    }
                    .contacts-mail-block{
                        width: 157px;
                        display: flex;
                        align-items: center;
                        .mail-img{
                            padding: 4px 10px 0px 0px;
                        }
                        .mail-link a{
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 140%;
                            color: #0E0E0E;
                            text-decoration: none;
                        }
                    }
                }
            }
            @media (max-width:1025px) {
                .footer-nav-wrapper{
                    width: 490px;
                    padding: 30px 25px 0px 30px ;
                }
            }
            @media (max-width:533px) {
                .footer-nav-wrapper{
                    width: 490px;
                    padding: 30px 0px 0px 20px ;
                }
            }
            .footer-bottom{
                padding-top: 117px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
            .footer-bottom a {
                text-decoration: none;
                color: #0E0E0E;
            }
            .footer-bottom a:hover{
                color: #D30D1E;
            }
        }
    }
    
}
@media (max-width:820px) {
    .footer-wrapper{
        flex-direction: column;
        .footer-left{
            max-width: 100%;
            .left-content{
                margin: 0 auto;
            }
        }
        .footer-right{
            margin: 0 auto;
        }
    }
}
@media (max-width:533px) {
    .footer-wrapper{
        flex-direction: column;
        .footer-left{
            max-width: 100%;
            .left-content{
                margin: 0 auto;
                .footer-capture{
                    text-align: center;
                }
            }
            
        }
        .footer-right{
            margin: 0;
           
            .right-content{
                padding: 50px 0px 0px 0px;
                .footer-nav-wrapper{
                    width: 60%;
                    display: flex;
                    flex-direction: column;
                    .footer-nav{
                        padding-left: 20px;
                        .footer-links{
                            padding: 20px 0px 0px 0px;
                        }
                    }
                    .footer-contacts{
                        padding: 20px 0px 0px 20px;
                    }
                }
                .footer-bottom{
                    padding: 45px 0px 40px 20px;
                }
                .footer-logo{
                    padding: 0px 0px 0px 20px;
                   
                }
            }
        }
    }
}