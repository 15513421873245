.team-wrapper {
    max-width: 1200px;
    margin: 0 auto;

    .team-wrapper-content {
        width: calc(100% - 40px);
        margin: 0 auto;
        .team-content {
            opacity: 0;
            transition: all 1s;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -ms-transition: all 1s;
            -o-transition: all 1s;

            width: 100%;

            .team-capture {
                text-align: center;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                padding: 0px 0px 40px 0px;
            }

            @media (max-width:428px) {
                .team-capture {
                    font-size: 30px;
                    line-height: 27px;
                }

            }

            .team-image {
                width: 100%;
                padding-bottom: 40px;
            }

            .team-image img {
                width: 100%;
                height: auto;
            }

            .job-title-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;

                .job-item {
                    width: 200px;
                    padding-bottom: 24px;
                    padding-right: 0px;
                    text-align: center;

                    .job-item-name {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        padding-bottom: 10px;
                    }

                    .job-item-jobTittle {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        color: #555555;
                    }
                }

                @media (max-width:428px) {
                    .job-item {
                        width: 100%;
                        padding-right: 0px;
                        text-align: center;
                    }
                }
            }

            @media (max-width:1025px) {
                .job-title-wrapper {
                    justify-content: center;
                }
            }

            @media (max-width:600px) {
                .job-title-wrapper {
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }

        .team-content-active {
            opacity: 1;
            transition: all 1s;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -ms-transition: all 1s;
            -o-transition: all 1s;
        }
    }

}