.header-wrapper{
    max-width: 100%;
    position: sticky;
    top:0px;
    background-color: #ffff;
    z-index: 1001;
    border-bottom: 1px solid #bbbbbb;
    .header-content{
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 112px;
        .header-logo{
            width: 220px;
        }
        .header-logo img{
            width: 120%;
            height: auto;
        }
        .nav-wrapper{
            max-width:820px ;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .nav-item{
                padding: 30px 15px 30px 15px;
                cursor: pointer;
            }
            .nav-item :hover{
                color: #D30D1E;
            }
            .nav-item a{
                text-decoration: none;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                color: #0E0E0E;
            }
        }
        .language-block-and-button-wrapper{
            display: flex;
            align-items: center;
            max-width: 306px;
            padding: 0px 20px 0px 0px;
            .button-wrapper{
                width: 227px;
                height: 56px;
                border: 1px solid #0E0E0E;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
@media (max-width:1025px) {
    .header-wrapper{
        display: none;
    }
}