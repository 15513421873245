.rezults-wrapper{
    width: 100%;
    background-image: url(../../assets/img/rezultsBg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 160px;
    position: relative;
    z-index: 2;
    .rezults-wrapper-content{
        padding: 70px 0px 70px 0px;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .rezults-text-block{
            opacity: 0;
            transition:all 1s;
            -webkit-transition:all 1s;
            -moz-transition:all 1s;
            -ms-transition:all 1s;
            -o-transition:all 1s;
            max-width: 600px;
            text-align: center;
            .rezults-header{
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
            }

            .rezults-description{
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;
                padding: 20px 0px 30px 0px;
            }
            @media (max-width:428px) {
                .rezults-header{
                    font-size: 30px;
                    line-height: 27px;
                }
                .rezults-description{
                    width: 90%;
                    text-align: center;
                    font-size: 18px;
                    line-height: 140%;
                    margin:  0 auto;
                }
            }
        }
        .active{
            opacity: 1;
            transition: all 1s;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -ms-transition: all 1s;
            -o-transition: all 1s;
        }
        .achievements-block-wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .achievements-item{
                opacity: 0;
                transform: translateY(80px);
                -webkit-transform: translateY(80px);
                -moz-transform: translateY(80px);
                -ms-transform: translateY(80px);
                -o-transform: translateY(80px);
                transition: all 1s;
                -webkit-transition: all 1s;
                -moz-transition: all 1s;
                -ms-transition: all 1s;
                -o-transition: all 1s;
                width: 360px;
                height: 200px;
                background: #FFFFFF;
                box-shadow: 4px 4px 20px rgba(14, 14, 14, 0.1);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                text-align: center;
                .red-capture{
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 44px;
                    color: #D30D1E;
                    padding: 50px 0px 10px 0px;
                }
                .achievements-description{
                    color: #0E0E0E;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                    padding: 0px 20px 0px 20px;
                }
            }
            .achievements-item-active{
                opacity: 1;
                transition: all 1s;
                -webkit-transition: all 1s;
                -moz-transition: all 1s;
                -ms-transition: all 1s;
                -o-transition: all 1s;
                transform: translate(0px);
                -webkit-transform: translate(0px);
                -moz-transform: translate(0px);
                -ms-transform: translate(0px);
                -o-transform: translate(0px);
}
            @media (max-width:1025px) {
                .achievements-item{

                    margin: 20px auto;
                }
            }
        }
    }
    @media (max-width:1025px) {
        .rezults-wrapper-content{
            padding-bottom: 0px;
        }
    }
}