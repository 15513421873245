.advanages-wrapper-position {
    width: 100%;
    padding: 150px 0px 110px 0px;
    display: block;
    .advanages-wrapper {
        display: block;
        width: 100%;
       background-image: url(../../assets/img/adBg.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .advanages-content-wrapper {
            max-width: 1200px;
            margin: 0 auto;

            .advanages-content {
                width: 100%;

                .advanages-capture {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 44px;
                    text-align: center;
                    padding-bottom: 20px;
                }

                @media (max-width:428px) {
                    .advanages-capture {
                        font-size: 32px;
                        line-height: 27px;
                    }

                }

                .advanages-card-wrapper-center {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .advanages-card-item {
                        -webkit-transform: translateY(70px);
                        -moz-transform: translateY(70px);
                        -ms-transform: translateY(70px);
                        -o-transform: translateY(70px);
                        position: relative;
                        width: 360px;
                        height: 314px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        transform: translateY(70px);
                        opacity: 0;
                        transition: all 1s;
                        -webkit-transition: all 1s;
                        -moz-transition: all 1s;
                        -ms-transition: all 1s;
                        -o-transition: all 1s;

                        .advanages-img {
                            position: absolute;
                            left: 198px;
                            top: 0px;
                        }

                        .advanages-text-block {
                            height: 242px;
                            background: #FFFFFF;
                            box-shadow: 4px 4px 20px rgba(14, 14, 14, 0.15);
                            border-radius: 10px;

                            .advanages-text-wrapper {
                                padding: 88px 24px 0px 24px;

                                .advanages-item-capture {
                                    font-weight: 600;
                                    font-size: 25px;
                                    line-height: 30px;
                                }

                                .advanages-item-description {
                                    padding-top: 12px;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 140%;
                                    color: #555555;
                                }
                            }
                        }
                    }

                    .advanages-card-item-active {
                        opacity: 1;
                        transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                        -moz-transform: translateY(0px);
                        -ms-transform: translateY(0px);
                        -o-transform: translateY(0px);
                        transition: all 1s;
                        -webkit-transition: all 1s;
                        -moz-transition: all 1s;
                        -ms-transition: all 1s;
                        -o-transition: all 1s;
                    }

                    @media (max-width:1025px) {
                        .advanages-card-item {
                            margin: 20px auto;
                        }
                    }
                }

                .advanages-bottom-wrapper {
                    max-width: 780px;
                    margin: 0 auto;

                    .advanages-card-wrapper-bottom {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        padding-top: 20px;

                        .advanages-card-item-bottom {
                            transform: scale(0);
                            -webkit-transform: scale(0);
                            -moz-transform: scale(0);
                            -ms-transform: scale(0);
                            opacity: 0;
                            position: relative;
                            width: 360px;
                            height: 314px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: center;
                            transform: translateY(-70px);
                            -webkit-transform: translateY(-70px);
                            -moz-transform: translateY(-70px);
                            -ms-transform: translateY(-70px);
                            -o-transform: translateY(-70px);
                            transition: all 1s;
                            -webkit-transition: all 1s;
                            -moz-transition: all 1s;
                            -ms-transition: all 1s;
                            -o-transition: all 1s;

                            .advanages-img {
                                position: absolute;
                                left: 198px;
                                top: 0px;
                            }

                            .advanages-text-block {
                                height: 242px;
                                background: #FFFFFF;
                                box-shadow: 4px 4px 20px rgba(14, 14, 14, 0.15);
                                border-radius: 10px;

                                .advanages-text-wrapper {
                                    padding: 88px 24px 0px 24px;

                                    .advanages-item-capture {
                                        font-weight: 600;
                                        font-size: 25px;
                                        line-height: 30px;
                                    }

                                    .advanages-item-description {
                                        padding-top: 12px;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 140%;
                                        color: #555555;
                                    }
                                }
                            }
                        }

                        .advanages-card-item-bottom-active {
                            transform: scale(1);
                            -webkit-transform: scale(1);
                            -moz-transform: scale(1);
                            -ms-transform: scale(1);
                            opacity: 1;
                            transform: translateY(0px);
                            -webkit-transform: translateY(0px);
                            -moz-transform: translateY(0px);
                            -ms-transform: translateY(0px);
                            -o-transform: scale(1);
                            transition: all 1s;
                            -webkit-transition: all 1s;
                            -moz-transition: all 1s;
                            -ms-transition: all 1s;
                            -o-transition: all 1s;
                        }
                    }

                    @media (max-width:801px) {
                        .advanages-card-wrapper-bottom {
                            max-width: 760px;
                            margin: 0 auto;
                        }
                    }

                    @media (max-width:718px) {
                        .advanages-card-wrapper-bottom {
                            .advanages-card-item {
                                margin: 0 auto;
                            }
                        }
                    }

                    @media (max-width:724px) {
                        .advanages-card-wrapper-bottom {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                @media (max-width:1025px) {
                    .advanages-bottom-wrapper {
                        max-width: 868px;
                    }
                }
            }
        }
    }
}
@media (max-width:600px) {
    .advanages-wrapper-position{
        padding: 50px 0px 80px 0px;
    }
}