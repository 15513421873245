.services-wrapper{
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    width: 100%;
    background: #ffff;
    .servicer-wrapper-content{
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        .services-items-wrapper{
            box-sizing: border-box;
            overflow: hidden;
            height: 442px;
        }
        @media (max-width:801px) {
            .services-items-wrapper{
                height: 893px;
            }
        }
        @media (max-width:428px) {
            .services-items-wrapper{
                height: 826px;
            }
        }
        .servicer-wrapper-content-active{
            height: min-content;
        }
        .services-header-wrapper{
            width: 100%;

            padding: 180px 0px 0px 0px;
            .services-header-capture{
                text-align: center;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                padding: 0px 0px 40px 0px;
            }
            @media (max-width:428px) {
                .services-header-capture{
                    font-size: 30px;
                    line-height: 27px;
                }
            }
            @media (max-width:801px) {
                .services-header-capture{
                    width: 90%;
                    margin:  0 auto;
                    border-bottom: 1px solid #bbbbbb;
                }
            }
            .services-header{
                width: 100%;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid #bbbbbb;
                border-bottom: 1px solid #bbbbbb;
                .services-item{
                    width: 255px;
                    //padding: 0px 60px 0px 0px ;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                }
                @media (max-width:1025px) {
                    .services-item{
                        padding: 0px 60px 0px 10px ;
                    }
                }
            }
            @media (max-width:801px) {
                .services-header{
                    display: none;
                }
            }
        }
        @media (max-width:801px) {
            .services-header-wrapper{
                padding-top: 80px;
            }
        }
        .services-cover-part-wrapper{
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            bottom: -164px;
            background: #ffff;
            opacity: 0.8;
            z-index: 1;
            cursor: pointer;
            .services-cover-content{
                display: flex;
                justify-content: space-between;
                padding: 175px 0px 60px 0px;
                .cover{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 140%;
                    color: #D30D1E;
                }
                .arrows{
                    padding-left: 12px;
                }
            }
            .services-cover-content-active{
                padding: 50px 0px 60px 0px;
            }
        }
    }
}
.active{
    opacity: 1;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
}
