.services-block-item-wrapper{
    width: 100%;
    padding: 30px 0px 30px 0px;
    border-bottom: 1px solid #bbbbbb;
    .services-block-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mob-capture{
            display: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            padding-bottom: 10px;
        }
        
        .services-block-item{
            width: 255px;
           // padding: 0px 60px 0px 0px;
            .services-block-capture{
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                padding-bottom: 6px;
            }
            .services-block-explanation{
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #A9A9A9;

            }
            .base-item{
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
            }
            .services-item-price{
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
            }
        }
        .services-button-wrapper{
            width: 255px;
            height: 56px;
            background: #D30D1E;
            border-radius: 10px;
        }
        .services-button-wrapper button{
            color: #ffff;
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
        }
    }
    @media (max-width:801px) {
        .services-block-content{
            flex-direction: column;
            align-items: center;
            justify-content: center;
        
            .mob-capture{
                display: block;
            }
        }
    }
}
@media (max-width:1025px) {
    .services-block-item-wrapper{
        .services-block-content{
            padding: 0px 10px 0px 10px;
            .services-block-item{
                padding: 0px;
            }
        }
    }
    
}
@media (max-width:801px) {
    .services-block-item-wrapper{
        width: 90%;
        margin:  0 auto;
        .services-block-content{
            .services-block-item{
                padding-bottom: 12px;
            }
        }
    }
    
}
@media (max-width:428px) {
    .services-block-item-wrapper{
        width: 90%;
        margin:  0 auto;
        .services-block-content{
            .services-block-item{
                width: 100%;
                padding-bottom: 12px;
                
            }
            .services-button-wrapper{
                width: 100%;
            }
            .mob-capture{
                width: 100%;
                text-align: start;
            }
        }
        
    }
    
}